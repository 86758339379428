import React, {Component, Fragment} from 'react'
import Photo from '../react/Photo'
import PizzaToppings from './ModifierWidgets/PizzaToppings'
import Buffet from './ModifierWidgets/Buffet'
import Price from './Price'
import makeCloudinaryUrlForPhoto from '../afa-photo'
import {get} from 'lodash'


function ModifierWidgetsSwitch (props) {
  let type = props.type
  // if (props.pizzaSections) {
  //   type = "pizza_toppings"
  // }
  switch (type) {
    case 'single':
      return <SingleChoice {...props} />
      break;
    case 'pizza':
      return <PizzaToppings {...props} />
    case 'buffet':
      return <Buffet {...props} />
    default:
      return <MultipleChoice {...props} />

  }
}

//  <div className={"modifier " + className} key={modifier.id}>
const SingleChoice = ({modifiers, editCustomerChoice, customerPicks}) => {
  let options = {}
  modifiers.forEach(({id}) => options[id] = false)
  return (<div className="product-modifiers">
    {modifiers.map(modifier => {
      let picked = customerPicks[modifier.id] || false
      let pickedClassName = picked ? 'picked' : ''
      return(


        <div className={"modifier " + pickedClassName}>
          <input id={modifier.id} type="checkbox"
            checked={customerPicks[modifier.id] || false}
            onChange={(event) => editCustomerChoice(Object.assign({}, options, {[modifier.id]: event.target.checked}))}
          />

          <label htmlFor={modifier.id}
            className={pickedClassName}
            key={modifier.id}>
            <span
              className="text"
              role="text">
              {modifier.url ? <div className="photo-container">
                <Photo url={modifier.url} width={80} height={80} alt="" />
              </div> : null}
              <span className="modifier-name">{modifier.name || modifier.id}</span>
              <Price modifier={modifier} customerPicks={customerPicks}></Price>
            </span>
          </label>
        </div>

    )})}
  </div>)
}

const MultipleChoice = ({modifiers=[], editCustomerChoice, customerPicks}) => {
  let options = {}
  modifiers.forEach(({id}) => options[id] = false)
  return (<div className="product-modifiers">
    {modifiers.map(modifier => {
      let picked = customerPicks[modifier.id] || false
      let pickedClassName = picked ? 'picked' : ''
      let photoURL
      if (modifier.photo) {
        photoURL = makeCloudinaryUrlForPhoto(modifier.photo, {width: 110})
      }
      return(
        <div className={"modifier " + pickedClassName}>
          <input id={modifier.id} type="checkbox"
            checked={picked}
            onChange={(event) => editCustomerChoice({[modifier.id]: event.target.checked})}
          />

          <label className={pickedClassName} htmlFor={modifier.id} key={modifier.id}>
            <span className="text" role="text">
              {modifier.url ? <div className="photo-container">
                <Photo url={modifier.url} width={80} height={80} alt="" />
              </div> : null}
              {photoURL ? <div className="photo-container">
                <img src={photoURL} width={80} alt="" />
              </div> : null}

              <span className="modifier-name">{modifier.name || modifier.id}</span>
              <Price modifier={modifier} customerPicks={customerPicks}></Price>
            </span>

          </label>



        </div>
    )})}
  </div>)
}

class CartItemModifiers extends Component {
  render() {
    let injectedCartItemId = this.props.injectedCartItemId
    let dispatch = this.props.dispatch
    let product = this.props.product
    let customerPicks = product.customerPicks || {}
    let resultsMap = this.props.resultsMap || []
    let {modgroups} = product
    if (!modgroups) {return null}
    let editCustomerChoice = (diffObject) => {
      dispatch({type: "EDIT_CUSTOMER_CHOICE", payload: diffObject})
    }
    return(<div className="modifiers-wrapper">
      {
        modgroups
        .filter(({modifiers}) => (!!modifiers && modifiers.length > 0))
        .map((modgroup) => {
          const {name, id, required, minCount, minPrice, maxCount} = modgroup
          if (name === 'quantity' || name === 'Quantity') { return null }
          const errorMsg = get(resultsMap[id], 'message', false)
          return(
            <fieldset className="product-modgroup"
              key={id}
              role="group"
              // aria-labelledby={`${  }`}
              >
              <h2 className="modgroup-header">
                <legend className="section-title">
                  <span role="text">{name}
                    {required &&
                      <span
                        aria-label=". required choice"
                        className="required-tag">required</span>}
                    <br/>
                    {maxCount &&
                      <span
                        // aria-label=". required choice"
                        className="required-tag">{maxCount} maximum selection</span>}
                    {minCount &&
                      <span
                        // aria-label=". required choice"
                        className="required-tag">{minCount} minimum selection</span>}
                    </span>
                    {minPrice &&
                      <span
                        // aria-label=". required choice"
                        className="required-tag">${minPrice} minimum total</span>}

                    {errorMsg ? 
                       <span
                       className="error-tag">{errorMsg}</span>
                      : null}

                </legend>
              </h2>
              <ModifierWidgetsSwitch {...modgroup} editCustomerChoice={editCustomerChoice}
                customerPicks={customerPicks}
              /></fieldset>
          )
        })
      }

    </div>)
  }
}

export default CartItemModifiers
