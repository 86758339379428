import React from 'react'
import './sections.scss'

export const sections = [
  // {
  //   link: "/wine-club",
  //   linkText: "Step into the tasting room",
  //   h1: "The Tasting Room",
  //   sub: "",
  //   content: "Join us in our wine cellar and enjoy a barrel sampling straight from the vineyard. Private tasting tour available.",
  //   miniBanner: "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomspecialsTAB-1-900x97.jpg",
  //   full: "Why buy from a sea of bottles when you can taste, like, and buy? Become a member of the UPW Tasting Club and immerse yourself in the best selection of white, red, desert wines, and even champagne in town! After you’re tasting join us in our wine cellar and enjoy a barrel sampling straight from the vineyard. Private tables can be booked. Joining us for a meal? Great choice! We make pairing your meal with the perfect wine simple! Receive three 1oz tastings for FREE and then choose bottle or glass of your choice varietal for your meal. Coming for a tasting? Wonderful! You’ll receive 2 oz pours for $15 OR FREE with a bottle purchase!",
  //   img: "https://afag.imgix.net/urban-press-winery/the-tasting-room.jpg?w=800",
  //   hero: "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomHEADER.jpg",
  //   gallery: [
  //     "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomGALLERY2-900x532.jpg",
  //     "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomGALLERY5-900x532.jpg",
  //     "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomGALLERY1-900x532.jpg",
  //     "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomGALLERY4-1-900x532",
  //     "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomGALLERY3-900x532.jpg",
  //     "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomGALLERY6-900x532.jpg",
  //     "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomGALLERY7-900x532.jpg",
  //     "http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomGALLERY8-900x532.jpg",
  //   ]
  // },
  {
    h1: "Breakfast & Lunch",
    sub: "Brick Oven Pizza & Others",
    img: "https://afag.imgix.net/harvest-moon/burrito-ginger-beer-1.jpg?w=800",
    // content: "",
    link: "/menu",
  },
  {
    h1: "Wine and Beer",
    // link: "/the-brick-",
    sub: "Live Music thursday - saturday",
    link: "/wine-and-beer",
    linkText: "Wine and Beer Menu",
    img: "https://afag.imgix.net/harvest-moon/wine-and-beer.jpg?w=800",
    content: "Harvest Moon offers a selection of craft beer and a curated wine list."
    // link: "/events",
    // linkText: "View Schedule"
  },

  {
    h1: "Marketplace & bakery",
    sub: "romatic dinner date",

    img: "https://afag.imgix.net/harvest-moon/marlet-place.png?w=800",
    content: "House made cold case and bakery take out and special gifts."

  },
  {
    h1: "Bakery",
    sub: "gather with friends",
    img: "https://afag.imgix.net/harvest-moon/bakery-1.png?w=800",
    content: ""
  },
  {
    h1: "Catering",
    sub: "gather with friends",
    img: "https://afag.imgix.net/harvest-moon/bakery-1.png?w=800",
    content: ""
  },

  {
    h1: "In-School Program",
    img: "https://afag.imgix.net/harvest-moon/in-school-program.jpg?w=800",
    hero: "https://afag.imgix.net/harvest-moon/in-school-program.jpg?w=1200",
    link: "/about-us/in-school-program",
    linkText: "details",
    pageContentComponent: function () {
      return(
        <article>
          <p>Harvest Moon is an innovator in School Lunch programs. Drawing upon her extensive experience as a corporate executive chef, Christine Drapkin knows how to make school dining a delicious, non-repetitive, and exciting experience. She has re-invented the school lunch program to be so much more than the daily food tray.</p>
          <p>Her philosophy is simple: Don’t underestimate your children. Serve them great tasting, nutritious, fresh food and teach them where it comes from. You will see them cultivate their own curiosity for great food and a desire to eat well.</p>
          <p>As a mother and expert on feeding children, Christine gained expertise at her own table. “My daughter Sydney went to farmers markets, food stores and restaurants when she was in her stroller. We’d grind her food in a small plastic food mill, and she ate what we did. I knew that by having her taste everything, early on, she’d learn to appreciate fresh, healthy food, and my hunch was right.”</p>
          <p>Harvest Moon expertly provides highly nutritious, organic, and tasty school lunches. Christine, or Chef Chris as she is known in school kitchens, has launched and managed daily food programs for schools in LA since 2007. For more information, contact chris@harvestmoonco.com</p>
        </article>
      )
    }
  }
]

export const sectionPagesDef = sections.map(({ link, linkText, h1: heading, full, hero, gallery=[], pageContentComponent }) => {
  if (!linkText) {return null}
  // if (!)
  return {
    path: link,
    hidden: true,
    component: () => (
      <div className="experience page">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundSize: "fill",
            backgroundPosition: "center"
          }}
          className="header">
          <div className="text">
            <h1>{heading}</h1>
          </div>
        </div>
        {/* <div className="mini-banner">
          <img
            src="http://new.urbanpresswinery.com/wp-content/uploads/2016/10/tastingroomspecialsTAB-1-900x97.jpg"
            alt=""/>
        </div> */}
        <div className="content">

          {pageContentComponent() || <p>{full}</p>}
        </div>

        <div className="gallery">
          {gallery.map((url) => {
            return(
              <div className="item">
                <img src={url} alt=""/>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}).filter((a) => !!a)
