
import * as Components from "./Costa"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "costa"
}

