import React from 'react'

const urlmapping = {
"/pages/events": {},
"/blogs/news": {},
"/pages/directions": {},
"/blogs/events-music": {},
"/collections/wines": {},
"/collections/wine-club": {},
"/collections/gift-cards": {},
"/collections/merchandise": {},
"/blogs/news/dogs-allowed-in-picnic-area-or-patio": {},
"/blogs/news/a-real-wine-connoisseur-tastes-shitty-wines-tipsy-bartender": {},
"/blogs/news/urban-press-winery-hotel": {},
"/collections/frontpage": {},
"/collections/frontpage/products/2010-urban-press-tre-vigne": {},
"/collections/frontpage/products/2012-urban-press-merlot": {},
"/collections/frontpage/products/2012-urban-press-petit-sirah": {},
"/collections/frontpage/products/2013-urban-press-cabernet-sauvignon": {},
"/collections/frontpage/products/2013-urban-press-chenin-blanc": {},
"/collections/frontpage/products/2013-urban-press-due-vigne": {},
"/collections/frontpage/products/2013-urban-press-forte-e-scurro-zinfandel": {},
"/collections/frontpage/products/2013-urban-press-rose": {},
"/collections/frontpage/products/2013-urban-press-sauvignon-blanc": {},
"/collections/frontpage/products/2013-urban-press-tre-vigne": {},
"/collections/frontpage/products/2013-urban-press-tutto-bianco": {},
"/collections/frontpage/products/2014-urban-press-cabernet-sauvignon-merlot": {},
"/collections/frontpage/products/2014-urban-press-castello-della-citta": {},
"/collections/frontpage/products/2014-urban-press-chardonnay": {},
"/apps/stream": {},
"/blogs/news/check-out-our-urban-press-spirits-pages": {},
"/blogs/news/sangria-recipes-for-summer-california-cookbook": {},
"/blogs/news/tagged/alcohol": {},
"/blogs/news/tagged/antioxidants": {},
"/blogs/news/tagged/babyboomers": {},
"/blogs/news/tagged/barrel-tasting": {},
"/blogs/news/tagged/best-wine": {},
"/blogs/news/tagged/blog": {},
"/blogs/news/tagged/bon-appetit": {},
"/blogs/news/tagged/burbank": {},
"/blogs/news/tagged/california": {},
"/blogs/news/tagged/cancer": {},
"/blogs/news/tagged/chicago-tribune": {},
"/blogs/news/tagged/chocolate": {},
"/blogs/news/tagged/chocolate-pairings": {},
"/blogs/news/tagged/couples": {},
"/blogs/news/tagged/couples-drinking-wine": {},
"/blogs/news/tagged/disease-treatmeant": {},
"/blogs/news/tagged/economy": {},
"/blogs/news/tagged/finedining": {},
"/blogs/news/tagged/flavor": {},
"/blogs/news/tagged/food-and-wine": {},
"/blogs/news/tagged/forbes": {},
"/blogs/news/tagged/grapes": {},
"/blogs/news/tagged/health": {},
"/blogs/news/tagged/heart": {},
"/blogs/news/tagged/heart-disease": {},
"/blogs/news/tagged/hearthealth": {},
"/blogs/news/tagged/how-to": {},
"/blogs/news/tagged/kosher-wine": {},
"/blogs/news/tagged/lacouple": {},
"/blogs/news/tagged/lesswine": {},
"/blogs/news/tagged/local-business": {},
"/blogs/news/tagged/medical-news-today": {},
"/blogs/news/tagged/millenials": {},
"/blogs/news/tagged/movies": {},
"/blogs/news/tagged/new-drinker": {},
"/blogs/news/tagged/new-york-times": {},
"/blogs/news/tagged/opinion": {},
"/blogs/news/tagged/oral-health": {},
"/blogs/news/tagged/pairings": {},
"/blogs/news/tagged/passover": {},
"/blogs/news/tagged/red-wine": {},
"/blogs/news/tagged/relationships": {},
"/blogs/news/tagged/sommelier": {},
"/blogs/news/tagged/spirits": {},
"/blogs/news/tagged/spring": {},
"/blogs/news/tagged/thrillist": {},
"/blogs/news/tagged/tim-hanni": {},
"/blogs/news/tagged/travel-guide": {},
"/blogs/news/tagged/urban-press-winery": {},
"/blogs/news/tagged/urbanpress": {},
"/blogs/news/tagged/urbanpresswinery": {},
"/blogs/news/tagged/vine": {},
"/blogs/news/tagged/vineyards": {},
"/blogs/news/tagged/washingtonpost": {},
"/blogs/news/tagged/white-wine": {},
"/blogs/news/tagged/wine": {},
"/blogs/news/tagged/wine-101": {},
"/blogs/news/tagged/wine-and-cheese": {},
"/blogs/news/tagged/wine-and-spirits": {},
"/blogs/news/tagged/wine-benefits": {},
"/blogs/news/tagged/wine-bottles": {},
"/blogs/news/tagged/wine-celebrations": {},
"/blogs/news/tagged/wine-club": {},
"/blogs/news/tagged/wine-enth": {},
"/blogs/news/tagged/wine-enthusiast": {},
"/blogs/news/tagged/wine-guide": {},
"/blogs/news/tagged/wine-history": {},
"/blogs/news/tagged/wine-list": {},
"/blogs/news/tagged/wine-lover": {},
"/blogs/news/tagged/wine-music": {},
"/blogs/news/tagged/wine-pairings": {},
"/blogs/news/tagged/wine-research": {},
"/blogs/news/tagged/wine-selection": {},
"/blogs/news/tagged/wine-spectator": {},
"/blogs/news/tagged/wine-store": {},
"/blogs/news/tagged/wine-study": {},
"/blogs/news/tagged/wine-tasting": {},
"/blogs/news/tagged/winery": {},
"/blogs/news/tagged/winestudy": {},
"/blogs/news/tagged/women": {},
"/blogs/news/tagged/women-and-men": {},
"/blogs/news?page=2": {},
"/blogs/news?page=3": {},
"/blogs/news?page=14": {},
"/collections/wines/products/2012-urban-press-late-harvest-cabernet-sauvignon": {},
"/collections/wines/products/2016-urban-press-raspberry-wine": {},
"/collections/wines/products/2012-urban-press-pinot-noir": {},
"/collections/wines/products/2013-urban-press-late-harvest-viognier": {},
"/collections/wines/products/2013-urban-press-cabernet-sauvignon": {},
"/collections/wines/products/2014-urban-press-castello-della-citta": {},
"/collections/wines/products/2013-urban-press-sauvignon-blanc": {},
"/collections/wines/products/2012-urban-press-lyric-champagne": {},
"/collections/wines/products/2014-urban-press-chardonnay": {},
"/collections/wines/products/2016-urban-press-tutto-bianco-1": {},
"/collections/wines/products/2010-urban-press-tre-vigne": {},
"/collections/wines/products/2016-urban-press-viogner": {},
"/collections/wines/products/2016-urban-press-sauvignon-blanc": {},
"/collections/wines/products/2016-urban-press-due-vigne-bianco": {},
"/collections/wines/products/2016-grenache-blanc-case-12-btls": {},
"/collections/wines/products/2014-urban-press-stricia-syrah": {},
"/collections/wines/products/2013-urban-press-mascherato": {},
"/collections/wines/products/2014-urban-press-cabernet-sauvignon": {},
"/collections/wines/products/2014-urban-press-due-vigne": {},
"/collections/wines/products/2013-urban-press-tre-vigne": {},
"/collections/wines?page=2": {},
"/collections/gift-cards/products/gift-cards": {},
"/collections/merchandise/products/urban-press-purse-hangers": {},
"/collections/merchandise/products/tuscany-wine-opener-boxed-set": {},
"/collections/merchandise/products/small-reusable-totes": {},
"/collections/merchandise/products/bamboo-cheese-cutting-board-with-knife-and-tray": {},
"/collections/merchandise/products/gift-cards": {},
"/collections/merchandise/products/stone-river-rock-wine-holder": {},
"/collections/merchandise/products/life-is-too-short-to-drink-bad-wine-sign": {},
"/collections/merchandise/products/cork-keychain-upw-logo": {},
"/collections/merchandise/products/dog-pourer": {},
"/collections/merchandise/products/single-leather-wine-carrier": {},
"/collections/merchandise/products/urban-press-winery-t-shirt": {},
"/collections/merchandise/products/riedel-merlot-wine-decanter": {},
"/collections/merchandise/products/urban-press-winery-baseball-hat": {},
"/collections/merchandise/products/double-leather-wine-carrier": {},
"/collections/merchandise/products/wine-pourer": {},
"/collections/merchandise/products/urban-press-wine-glass-charms": {},
"/collections/merchandise/products/wine-carrier-gel": {},
"/collections/merchandise/products/wine-opener-in-case": {},
"/collections/merchandise/products/electric-wine-opener": {},
"/collections/merchandise/products/urban-press-olive-oil-and-balsamic-vinegar": {},
"/collections/merchandise?page=2": {},
"/collections/frontpage/products/2014-urban-press-stricia-syrah": {},
"/collections/frontpage/products/2016-urban-press-tutto-bianco-1": {},
"/blogs/news/the-10-health-benefits-of-wine-will-inspire-you-to-pour-a-hearty-glass-tonight": {},
"/blogs/news/wine-term-teinturier-grapes": {},
"/blogs/news/should-wine-be-savored-or-guzzled-for-true-pleasure-mindset-matters": {},
"/blogs/news/a-beginner-s-guide-to-craft-chocolate": {},
"/blogs/news/health-watch-is-wine-the-key-to-a-long-life": {},
"/blogs/news/tagged/alcohol?page=2": {},
"/blogs/news/tagged/alcohol?page=3": {},
"/blogs/news/which-type-of-red-wine-is-the-healthiest": {},
"/blogs/news/we-may-be-drinking-less-wine-but-its-better-wine": {},
"/blogs/news/urban-press-winery-a-piece-of-wine-country-in-la": {},
"/blogs/news/kosher-wines-you-ll-want-to-drink-after-passover-yes-really": {},
"/blogs/news/wine-101-expand-your-palate": {},
"/blogs/news/white-wines-can-be-great-too": {},
"/blogs/news/we-asked-10-winemakers-what-s-your-favorite-wine-and-cheese-pairing": {},
"/blogs/news/tagged/blog?page=2": {},
"/blogs/news/tagged/blog?page=3": {},
"/blogs/news/5-questions-to-ask-when-youre-buying-wine-and-know-nothing-about-wine": {},
"/blogs/news/a-beginner-s-guide-to-wine-faults-and-flaws": {},
"/blogs/news/red-wine-vs-white-wine-the-real-differences": {},
"/blogs/news/how-california-became-a-food-and-wine-lovers-dream": {},
"/blogs/news/can-a-daily-glass-of-wine-help-prevent-cancer": {},
"/blogs/news/pour-wine-to-savor-and-enjoy-not-guzzle": {},
"/blogs/news/your-love-life-may-affect-your-taste-in-wine": {},
"/blogs/news/lsu-professor-uses-red-wine-to-create-heart-disease-treatment": {},
"/blogs/news/8-ways-to-be-smarter-about-ordering-wine-according-to-a-sommelier": {},
"/blogs/news/five-essential-tips-for-tackling-a-wine-list-like-a-pro": {},
"/blogs/news/a-different-look-at-wine-pairings-with-master-of-wine-tim-hanni": {},
"/blogs/news/tagged/food-and-wine?page=2": {},
"/blogs/news/drink-up-new-study-concludes-wine-can-offset-dementia": {},
"/blogs/news/tagged/health?page=2": {},
"/blogs/news/red-wine-proves-good-for-the-heart-again": {},
"/blogs/news/choose-the-right-wine-7-tips-on-how-to-shop-smarter": {},
"/blogs/news/skip-the-guilt-red-wine-could-protect-your-oral-health": {},
"/blogs/news/7-wine-documentaries-worth-your-time": {},
"/blogs/news/don-t-judge-a-wine-by-the-grape-on-its-label": {},
"/blogs/news/5-white-wine-styles-to-welcome-spring": {},
"/blogs/news/the-wine-lover-s-guide-to-the-finest-accommodations-amongst-the-world-s-juiciest-vineyards": {},
"/blogs/news/tagged/wine?page=2": {},
"/blogs/news/tagged/wine?page=3": {},
"/blogs/news/tagged/wine?page=7": {},
"/blogs/news/goat-cheese-wine-pairings": {},
"/blogs/news/behind-the-scenes-with-a-bordeaux-wine-consultant-stephane-courreges": {},
"/blogs/news/the-music-of-wine": {},
"/blogs/news/tagged/wine-and-spirits?page=2": {},
"/blogs/news/tagged/wine-and-spirits?page=3": {},
"/blogs/news/tagged/wine-and-spirits?page=4": {},
"/blogs/news/tagged/wine-enthusiast?page=2": {},
"/blogs/news/tagged/wine-enthusiast?page=3": {},
"/blogs/news/tagged/wine-lover?page=2": {},
"/blogs/news/tagged/wine-selection?page=2": {},
"/blogs/news/tagged/wine-selection?page=3": {},
"/blogs/news/tagged/wine-selection?page=4": {},
"/blogs/news/tagged/wine-study?page=2": {},
"/blogs/news/tagged/wine-tasting?page=2": {},
"/blogs/news/tagged/winery?page=2": {},
"/blogs/news/tagged/winestudy?page=2": {},
"/blogs/news/women-and-wine-how-alcohol-affects-female-health": {},
"/blogs/news/urban-press-winery-a-piece-of-wine-country-in-la-1": {},
"/blogs/news/five-essential-tips-for-tackling-a-wine-list-like-a-pro-1": {},
"/blogs/news?page=1": {},
"/blogs/news?page=4": {},
"/blogs/news/seven-ways-the-wine-world-will-change-in-2018": {},
"/blogs/news/skip-the-guilt-red-wine-could-protect-your-oral-health-1": {},
"/blogs/news/the-ultimate-guide-to-pairing-burgers-with-wine": {},
"/blogs/news?page=5": {},
"/blogs/news/urban-press-offers-a-piece-of-wine-country-in-downtown-burbank": {},
"/blogs/news/how-to-taste-wine-like-a-pro": {},
"/blogs/news?page=13": {},
"/blogs/news?page=12": {},
"/collections/wines/products/2013-urban-press-rose": {},
"/collections/wines/products/2013-urban-press-forte-e-scurro-zinfandel": {},
"/collections/wines/products/2013-urban-press-due-vigne": {},
"/collections/wines/products/2013-urban-press-chenin-blanc": {},
"/collections/wines/products/2012-urban-press-petit-sirah": {},
"/collections/wines/products/2012-urban-press-mascherato": {},
"/collections/wines/products/2012-urban-press-cabernet-sauvignon": {},
"/collections/wines/products/2013-urban-press-tutto-bianco": {},
"/collections/wines/products/2014-urban-press-cabernet-sauvignon-merlot": {},
"/collections/wines/products/2012-urban-press-merlot": {},
"/collections/wines?page=1": {},
"/collections/merchandise/products/sonoma-carafe": {},
"/collections/merchandise?page=1": {},
"/blogs/news/tagged/alcohol?page=1": {},
"/blogs/news/tagged/blog?page=1": {},
"/blogs/news/check-us-out-in-the-febuary-2017-edition-of-southwest-magazine": {},
"/blogs/news/tagged/food-and-wine?page=1": {},
"/blogs/news/tagged/health?page=1": {},
"/blogs/news/tagged/wine?page=1": {},
"/blogs/news/tagged/wine?page=4": {},
"/blogs/news/tagged/wine?page=5": {},
"/blogs/news/tagged/wine?page=6": {},
"/blogs/news/tagged/wine-and-spirits?page=1": {},
"/blogs/news/tagged/wine-enthusiast?page=1": {},
"/blogs/news/tagged/wine-lover?page=1": {},
"/blogs/news/tagged/wine-selection?page=1": {},
"/blogs/news/tagged/wine-study?page=1": {},
"/blogs/news/tagged/wine-tasting?page=1": {},
"/blogs/news/tagged/winery?page=1": {},
"/blogs/news/tagged/winestudy?page=1": {},
"/blogs/news/banyuls-roussillon-s-ultimate-dessert-wine": {},
"/blogs/news?page=6": {},
"/blogs/news/off-the-vine-the-fine-art-of-wine-sniffing": {},
"/blogs/news?page=7": {},
"/blogs/news/wine-captain-sommelier": {},
"/blogs/news/membership-appreciation-day": {},
"/blogs/news/why-fish-and-red-wine-dont-mix": {},
"/blogs/news/bbq-rescues-foundation-launches-1st-ca-fundraiser-memorial-day-weekend-at-urban-press-winery": {},
"/blogs/news?page=11": {},
"/blogs/news/wine-mind-tricks": {},
"/blogs/news/wine-and-dementia-studies-clash-on-health-benefits": {},
"/blogs/news/rose-the-pink-wine-thats-red-hot-cbs-sunday-morning": {},
"/blogs/news/is-sauvignon-blanc-the-world-s-most-reliably-good-white-wine-it-sure-is-here-s-why": {},
"/blogs/news/inside-the-biggest-wine-hoax-in-history": {},
"/blogs/news?page=10": {},
"/blogs/news?page=8": {},
"/blogs/news?page=9": {},
"/blogs/news/chocolate-wine-true-love": {},
"/blogs/news/wine-could-help-solve-writer-s-block-says-study": {},
"/blogs/news/california-wine-country-fires-how-to-help": {},
"/blogs/news/drinking-out-loud": {},
"/blogs/news/how-can-drinking-wine-help-reduce-diabetes-risk": {},
"/blogs/news/how-can-i-prevent-cloudy-wine-glasses-ask-decanter": {},
"/blogs/news/the-18-noble-grapes-wine-challenge": {},
"/blogs/news/drinking-wine-could-keep-your-teeth-healthy-prevent-gum-disease": {},
}
const mappingArray = []
for (let key in urlmapping) {
  urlmapping[key].origin = key
  mappingArray.push(urlmapping[key])
}
export default mappingArray
