import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import About from './About'
import Contact from './Contact'
// import Menu from './Menu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import Wine from './Wine'
import Spirits from './Spirits'
import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import urls from './urls'
import PoweredBy from './PoweredBy'
import {sections, sectionPagesDef} from './sections'
import ThemeToggle from './ThemeToggle'

export const customOrderAppPaths = ["/", "/menu", "/order"]

const urlMappings = urls.filter(({content, redirect}) => {
  return !!content || !!redirect
}).map(({origin, content, redirect}) => {
  const pageObj = { path: origin, unlisted: true }
  if (content) {
    pageObj.component = content
  }
  if (redirect) {
    console.log(redirect)
    pageObj.component = () => (<Redirect to={redirect}></Redirect>)
  }
  return pageObj
})

export const pages = [
  {path: "/order", label: "Order" },
  {path: "/menu", label: "Menu" },
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/gallery.html", label: "Gallery", component: Gallery},
  // {path: "/breakfast-lunch", label: "breakfast/lunch", component: Kitchen},
  // {path: "/bakery", label: "Bakery", component: Bakery},
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/about-us", label: "About", component: About},
  // {path: "/just-the-facts", label: "Contact", component: Contact},
  // {path: "/delivery", label: "Delivery", component: Delivery},
  ...sectionPagesDef
].concat(urlMappings)

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]




export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">

        <div
          className="hero-banner">
          <div className="text">
            <h2 style={{
              color: "black",
              // textShadow: "-1px 1px 0 green",
              marginLeft: "1em",
              fontSize: "2em"
            }}>
               Tacos,<br/>Ceviche,<br/>Cold Beers
            </h2>

            {/* <div
              className="quick-links">
              <header>
                <img
                  src="https://afag.imgix.net/harvest-moon/header.png?w=500" alt="Harvest Moon"/>
              </header>
              <div className="content">
                {quickLinks.map(({to, label}) => {
                  return(
                    [<Link className="link" to={to}>{label}</Link>,
                    <div>~</div>]
                  )
                })}
              </div>
            </div> */}
          </div>
        </div>






      </div>
    );
  }
}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}
